
  import { SmartBannerConfig } from './index'
  export const config: SmartBannerConfig = {
    festivalName: 'La Folle Journée de Nantes',
    appTitle: 'Folle Journée de Nantes',
    appStoreId: '0',
    appleStoreUrl: 'https://apps.apple.com/fr/app/folle-journ%C3%A9e-de-nantes/id6698888076',
    googleStoreUrl: 'https://play.google.com/store/apps/details?id=to.chapi.follejournee',
    appIconUrl: 'https://static.follejournee.chapi.to/app-icon.jpeg',
  }
